/* Estilo para inputs inválidos */
.inputError {
    border: 1px solid #e74c3c; /* Vermelho para indicar erro */
    background-color: #fce4e4; /* Fundo claro para destacar */
}

/* Estilo para o resultado em caso de erro */
.resultError {
    color: #e74c3c; /* Texto em vermelho para indicar erro */
    background-color: #fce4e4; /* Fundo claro para destacar o erro */
}

.ruleOfThreeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #4CAF50;
    margin-bottom: 10px;
    text-align: center;
}

p {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.calculationSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px; /* Adiciona espaço entre as seções */
}

.row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-wrap: nowrap;
}

.swapContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.swapButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 3px 6px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.9rem;
    margin-bottom: 5px;
    transition: background-color 0.3s ease;
}

.swapButton:hover {
    background-color: #45a049;
}

.copyButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.copyButton:hover {
    background-color: #45a049;
}

.centerText {
    margin: 10px 0;
    font-weight: bold;
    color: #555;
    text-align: center;
}

.inputGroup {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.input {
    padding: 10px;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    font-size: 1rem;
    width: 100px;
    flex: none;
    margin-bottom: 5px;
}

.textInput {
    padding: 5px;
    margin: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    font-size: 0.9rem;
    width: 100px;
    color: #555;
}

.label {
    font-weight: bold;
    color: #555;
    margin: 0 10px;
    white-space: nowrap;
}

.result {
    font-weight: bold;
    color: #2e7d32;
    background-color: #e8f5e9;
    transition: background-color 0.3s ease;
}

.resultUpdated {
    background-color: #a5d6a7;
}

.copiedMessage {
    margin-top: 10px;
    color: #4CAF50;
    font-weight: bold;
}

@media (min-width: 768px) {
    .input {
        width: 150px;
        font-size: 1.2rem;
    }

    .textInput {
        width: 150px;
    }

    .row {
        justify-content: space-between;
        gap: 20px;
    }
}

.explanationSection {
    margin-top: 30px;
    text-align: left;
    width: 100%;
}

.explanationSection h3 {
    color: #4CAF50;
    margin-bottom: 10px;
}

.explanationSection p {
    color: #555;
    margin-bottom: 15px;
}

.shareButton {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.shareButton:hover {
    background-color: #1565c0;
}
.clearButton {
    background-color: #e53935;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.clearButton:hover {
    background-color: #d32f2f;
}
