.homePage {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.solversSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 90%;
}

.category {
    margin: 20px 0;
    width: 100%;
}

.cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajuste o valor mínimo conforme necessário */
    gap: 15px;
    margin-top: 10px;
}
