.footer {
    display: flex;
    flex-direction: column; /* Alinha itens em coluna em dispositivos menores */
    align-items: center;
    padding: 10px;
    background-color: #f1f1f1;
    border-top: 1px solid #ddd;
    width: 100%; /* Garante que o footer ocupe toda a largura */
    box-sizing: border-box; /* Inclui padding e border no cálculo de largura */
}

.footer nav {
    display: flex;
    flex-direction: column; /* Alinha links em coluna por padrão */
    gap: 10px;
}

.footer a {
    text-decoration: none;
    color: #333;
    font-size: 14px;
}

.footer a:hover {
    text-decoration: underline;
}

@media (min-width: 768px) { /* Alinha lado a lado em telas maiores */
    .footer nav {
        flex-direction: row; /* Alinha links em linha */
        gap: 15px;
    }
}
