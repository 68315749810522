/* src/pages/blog/BlogPage.module.css */
.blogContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

h1 {
    color: #4CAF50;
    margin-bottom: 10px;
}

p {
    color: #555;
    text-align: center;
    margin-bottom: 20px;
}

.articlesList {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

/* Estilo para tornar o card clicável */
.articleCard {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    color: inherit;
    transition: transform 0.2s;
}

.articleCard:hover {
    transform: scale(1.02);
}

h2 {
    color: #4CAF50;
    margin-bottom: 10px;
}

.readMoreLink {
    color: #1976d2;
    text-decoration: none;
    font-weight: bold;
    margin-top: 10px;
    display: inline-block;
}

.readMoreLink:hover {
    text-decoration: underline;
}
