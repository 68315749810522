.tipCalculatorContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    color: #4CAF50;
    margin-bottom: 10px;
    text-align: center;
}

p {
    text-align: center;
    color: #555;
    margin-bottom: 20px;
}

.inputSection {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.inputGroup {
    margin-bottom: 15px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.inputGroup label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.input {
    padding: 10px;
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    font-size: 1rem;
    width: 100%;
}

.resultSection {
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
}

.resultSection h3 {
    color: #4CAF50;
    margin-bottom: 10px;
}

.resultSection p {
    color: #555;
    margin-bottom: 10px;
}

.copiedMessage {
    margin-top: 10px;
    color: #4CAF50;
    font-weight: bold;
}

.shareButton {
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.shareButton:hover {
    background-color: #1565c0;
}
.clearButton {
    background-color: #e53935;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-top: 10px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.clearButton:hover {
    background-color: #d32f2f;
}

.copyButton {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.copyButton:hover {
    background-color: #45a049;
}