/* src/pages/blog/RegraDeTresParaCriancas.module.css */
.articleContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 5px; /* Diminuído o espaçamento entre os elementos */
    margin-bottom: 5px;
}

h1, h2 {
    color: #4CAF50;
    text-align: center;
    margin-bottom: 1px; /* Reduzido o espaçamento inferior */
}

p, ul, ol {
    color: #555;
    line-height: 1.4; /* Diminuído o espaçamento entre linhas */
}

ul, ol {
    margin-left: 20px;
    margin-bottom: 4px; /* Reduzido o espaçamento inferior */
}

.backToBlog {
    display: flex;
    justify-content: center;
    margin-top: 4px; /* Reduzido o espaçamento superior */
}

.backLink {
    color: #1976d2;
    text-decoration: none;
    font-weight: bold;
}

.backLink:hover {
    text-decoration: underline;
}
