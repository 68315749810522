.card {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
    /* Remover width: 100%; */
    text-decoration: none;
}

.card:hover {
    transform: translateY(-5px);
}

.card h3 {
    color: #4CAF50;
    margin-bottom: 10px;
}

.card p {
    color: #333;
}
