.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.logoLink {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    border-style: solid;
    padding: 5px;
    line-height: 1;
}

.tagline {
    font-size: 16px;
    font-style: italic;
}

/* Estilo para o link de ferramentas */
.toolsLink {
    text-decoration: none;
    background-color: #ffffff; /* Fundo branco para o botão */
    color: #4CAF50; /* Texto verde para contraste */
    padding: 8px 16px; /* Espaçamento */
    border-radius: 5px; /* Bordas arredondadas */
    font-size: 16px; /* Tamanho do texto */
    font-weight: bold; /* Texto em negrito */
    display: flex; /* Flex para alinhar o ícone e o texto */
    align-items: center; /* Alinha verticalmente */
    gap: 8px; /* Espaçamento entre o ícone e o texto */
    transition: background-color 0.3s ease; /* Transição suave */
    margin-bottom: 10px; /* Espaço inferior */
}

.toolsLink:hover {
    background-color: #e8e8e8; /* Mudança de cor ao passar o mouse */
}

.icon {
    font-size: 20px; /* Tamanho do ícone */
}

/* Responsividade para telas maiores */
@media (min-width: 768px) {
    .header {
        flex-direction: row;
    }

    .logo,
    .tagline {
        margin-bottom: 0;
    }

    .toolsLink {
        margin-bottom: 0;
    }
}
